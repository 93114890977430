import { compose } from 'redux'
import Login from '../src/login/Login'
import withAuthentication from '../src/withAuthentication'
// @ts-ignore
import { LOGIN, permissions } from '../src/routes'

export default compose(withAuthentication(permissions[LOGIN]))(
  // @ts-ignore
  Login
)
